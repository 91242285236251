import React, { Component } from 'react';
import './style.css';
import io from 'socket.io-client';
import Aux from "../../Components/Aux"
import { Container, Grid, Comment } from 'semantic-ui-react'
import dateFormat from 'dateformat'
import 'whatwg-fetch'

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      messages: []
    }

    this.initSocket = this.initSocket.bind(this)

    fetch("https://rcs-scuola48-api.reportcongressi.com/old-messages").then(function(res) {
      return res.json()
    }).then(function(json) {
      this.setState({messages: json})
      this.initSocket()
    }.bind(this)).catch(function (err) {
      console.log(err)
      this.initSocket()
    }.bind(this))

  }
  initSocket() {
    this.socket = io("https://rcs-scuola48-api.reportcongressi.com", { query: "token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6IjAiLCJuYW1lIjoiTW9kZXJhemlvbmUiLCJzdXJuYW1lIjoiQ2hhdCIsImVtYWlsIjoibnVsbEBqb3kuaXQifQ.bDAnnM2T005TRz938XLIt-KJz5NeHlIg8IHs7YuWc4U" });
    this.socket.on("message.stream", function (body) {
      var newMessages = this.state.messages
      newMessages.push(body)
      this.setState({messages: newMessages})
    }.bind(this));
  }

  componentDidMount() {
    if (this.chatbox) {
      this.chatbox.scrollTop = this.chatbox.scrollHeight
    }
  }

  componentDidUpdate() {
    if (this.chatbox) {
      this.chatbox.scrollTop = this.chatbox.scrollHeight
    }
  }

  sendMessage() {
    console.log(this.state.messageText);
    if (this.socket && this.socket.connected) {
      this.socket.emit("message.new", {message: this.state.messageText})
      this.setState({messageText: ""})
    }
  }

  render() {
    return (
      <Aux>
        <Container fluid style={{height: "100%"}}>
          <Grid stretched style={{height: "100%"}}>
            <Grid.Column computer={16}>
              <div style={{display: "flex", flexDirection: "column"}}>
                <div className="ui segment chatbox" ref={(segment) => { this.chatbox = segment }} >
                  <div>
                    <Comment.Group>
                      {
                        this.state.messages.map(function(object) {
                          return (
                            <Comment key={object.author.name.toLowerCase().replace(" ", "_")+dateFormat(object.date, "yyyymmddHHMMss")}>
                            <Comment.Content>
                              <Comment.Author as='a'>{object.author.name} - {object.author.school !== undefined ? object.author.school : ""} - {object.author.city !== undefined ? object.author.city : ""} </Comment.Author>
                              <Comment.Metadata>
                                <div>{/*Today at 5:42PM*/}{dateFormat(object.date, "HH:MM")}</div>
                              </Comment.Metadata>
                              <Comment.Text>{object.message}</Comment.Text>
                            </Comment.Content>
                          </Comment>
                          )
                        })
                      }
                    </Comment.Group>
                  </div>
                </div>
              </div>
            </Grid.Column>
            <style>
              {`
                @media(max-width: 990px) {
                  .hide-mobile {
                    display: none !important;
                  }
                }
              `}
            </style>
          </Grid>
        </Container>
      </Aux>
    );
  }
}