import React, { Component } from 'react';
import './style.css';
import io from 'socket.io-client';
import Aux from "../../Components/Aux"
import { Redirect } from 'react-router-dom'
import { Image, Container, Grid, Form, Segment, Input, Button, Comment } from 'semantic-ui-react'
import dateFormat from 'dateformat'
import 'whatwg-fetch'
import Speakers from '../Speakers';
import Footer from '../Footer';
import History from '../History';
import Header from '../Header';
import Logos from '../Logos';

export default class Home extends Component {

  constructor(props) {
    super(props);

    this.state = {
      redirectToLogin: false,
      messageText: "",
    }

    this.initSocket = this.initSocket.bind(this)

    if (localStorage.getItem("jwtFinal")) {
      fetch("https://rcs-scuola48-api.reportcongressi.com/old-messages").then(function(res) {
        return res.json()
      }).then(function(json) {
        this.setState({messages: json})
        this.initSocket()
      }.bind(this)).catch(function (err) {
        console.log(err)
        this.initSocket()
      }.bind(this))

    } else {
      this.state.redirectToLogin = true
    }
  }

  initSocket() {
    this.socket = io("https://rcs-scuola48-api.reportcongressi.com", { query: "token="+localStorage.getItem("jwtFinal") });
    this.socket.on("message.stream", function (body) {
    }.bind(this));
  }

  componentDidMount() {
    if (this.chatbox) {
      this.chatbox.scrollTop = this.chatbox.scrollHeight
    }
  }

  componentDidUpdate() {
    if (this.chatbox) {
      this.chatbox.scrollTop = this.chatbox.scrollHeight
    }
  }

  sendMessage() {
    if (this.state.messageText.trim() === "") return;

    console.log(this.state.messageText);
    if (this.socket && this.socket.connected) {
      this.socket.emit("message.new", {message: this.state.messageText})
      this.setState({messageText: ""})
    }
  }

  render() {
    return (
      <Aux>
        {this.state.redirectToLogin && (
          <Redirect to="/login" />
        )}
        <Header />
        <Container fluid style={{marginTop: 20}} className="main-container">
          <Grid centered>
            <Grid.Column computer={12} tablet={16} mobile={16}>
              <Segment>
                <div className="ui embed">
                <iframe src="https://www.youtube.com/embed/fST7uvqdCKg?autoplay=1&rel=0&modest_branding=1" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
              </Segment>
            </Grid.Column>
            <Grid.Column computer={12} tablet={16} mobile={16}>
              <Segment className="form-segment">
                <h3 className="time">8 ottobre 2024, ore 11</h3>
                <h1 className="title">
                  INSIEME PER <b>CAPIRE</b><br />
                  <b>LE PAROLE SONO PIETRE.</b> RICONOSCERE E CONTRASTARE LA LINGUA DELL'ODIO
                </h1>
                <Form onSubmit={ this.sendMessage.bind(this) }>
                  <Input value={this.state.messageText} onChange={e => { this.setState({messageText: e.target.value}) }} placeholder="Invia una domanda..." action fluid style={{height: 40}}>
                    <input />
                    <Button type="submit">Invia</Button>
                  </Input>
                </Form>
              </Segment>
            </Grid.Column>
            <style>
              {`
                @media(max-width: 990px) {
                  .hide-mobile {
                    display: none !important;
                  }
                }
              `}
            </style>
          </Grid>
        </Container>
        <Speakers />
        <Footer />
      </Aux>
    );
  }
}